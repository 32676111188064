import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import url from 'url';

export interface SiteMetadata {
  siteMetadata: {
    title: string;
    siteUrl: string;
    description: string;
    author: {
      name: string;
      avatar: string;
    };
  };
}

interface SEOProps {
  lang?: string;
  meta?: string[];
  keywords?: string[];
  description?: string;
  robots?: string;
  title?: string;
  slug?: string;
  featuredImage?: string;
}

const SEO: FC<SEOProps> = ({ title, description, slug, featuredImage }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<{ site: SiteMetadata }>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const helmetTitle = title ? title : siteMetadata.title;
  const helmetDescription = description ? description : siteMetadata.description;
  const helmetCanonicalUrl = slug ? url.resolve(siteMetadata.siteUrl, slug) : siteMetadata.siteUrl;
  const helmetFeaturedImage = featuredImage ? `${siteMetadata.siteUrl}${featuredImage}` : null;

  // TODO: maybe change og:type to website for not blog page and twitter:card to summary. Also maybe add twitter:site and twitter:creator metas
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{helmetTitle}</title>
        <meta name="description" content={helmetDescription} />
        <link rel="canonical" href={helmetCanonicalUrl} />
        {/*  OpenGraph tags https://ogp.me/#metadata */}
        <meta property="og:title" content={helmetTitle} />
        <meta property="og:type" content="article" /> {/* https://ogp.me/#types */}
        <meta property="og:description" content={helmetDescription} />
        <meta property="og:url" content={helmetCanonicalUrl} />
        {/* Twitter cards https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup, https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started */}
        <meta name="twitter:card" content={helmetFeaturedImage ? 'summary_large_image' : 'summary'} />
        <meta name="twitter:title" content={helmetTitle} />
        <meta name="twitter:description" content={helmetDescription} />
      </Helmet>

      {helmetFeaturedImage && (
        <Helmet>
          <meta name="og:image" content={helmetFeaturedImage} />
          <meta name="og:image:alt" content={`Featured image: ${helmetTitle}`} />
          <meta name="twitter:image" content={helmetFeaturedImage} />
          <meta name="twitter:image:alt" content={`Featured image: ${helmetTitle}`} />
        </Helmet>
      )}
    </>
  );
};

export { SEO };
