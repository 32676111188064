import {
  container,
  main,
  mainRaised,
  grayColor,
  breadcrumbs,
  coloredShadow,
  cardTitle,
  description,
  gridItem,
  gridContainer,
  title,
  whiteColor,
  mlAuto,
  infoColor,
  successColor,
  dangerColor,
  warningColor,
} from '../styles/materialKitBase';

import { createStyles } from '@material-ui/core/styles';
import { footerStyle } from '../components/Footer/FooterStyle';
import { imagesStyles } from '../styles/imagesStyles';

export const postStyle = createStyles({
  ...imagesStyles,
  ...footerStyle,
  mlAuto,
  gridContainer,
  gridItem,
  description,
  cardTitle,
  coloredShadow,
  breadcrumbs,
  main: {
    ...main,
    ...mainRaised,
  },
  mainRaised,
  title: {
    ...title,
    color: whiteColor,
  },
  subtitle: {
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
  },
  container: {
    ...container,
    zIndex: 2,
  },
  // section: {
  //   ...section,
  //   padding: '70px 0px',
  // },
  section: {
    // paddingBottom: '0',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    padding: '70px 0',
    '& p, & li, & td': {
      fontSize: '1.188rem',
      // lineHeight: '1.5em',
      color: grayColor[15],
      fontWeight: '300',
      // marginBottom: '22px',
    },
    '& th': {
      fontSize: '1.188rem',
      lineHeight: '1.5em',
    },
    '& h2': {
      // lineHeight: '2.2rem',
      // For article title we use the Material UI variant="h3" which is 48px
      // To keep the rhythm, we try to use here the Material UI Typography variant="h4", which was 34px
      // Since 1rem is 16px, we do 34/16 and gives 2.125rem
      fontSize: '2.125rem',
    },
    '& h3': {
      // lineHeight: '1.5rem',
      // For article title we use the Material UI variant="h3" which is 48px
      // To keep the rhythm, we try to use here the Material UI Typography variant="h5", which was 24px
      // Since 1rem is 24px, we do 24/16 and gives 1.5rem
      fontSize: '1.5rem',
    },
  },
  icon: {
    marginRight: '5px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  parallax: {
    // height: '90vh',
    // overflow: 'hidden',
  },
  blogPostPage: {
    '& $mainRaised': {
      // margin: '-40vh 0 0',
      padding: '40px',
      // margin: '2vh 0 0',
      // padding: '20px',
    },
    // backgroundColor: grayColor[2],
  },
  quote: {
    padding: '1px 5px',
    margin: '0 0 1px',
    fontSize: '1.25rem',
    borderLeft: '5px solid ' + grayColor[2],
  },
  quoteText: {
    margin: '0 0 10px',
    fontStyle: 'italic',
  },
  stats: {
    color: grayColor[2],
    display: 'flex',
    justifyContent: 'center',
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      top: '3px',
      marginRight: '3px',
      marginLeft: '3px',
      fontSize: '18px',
      lineHeight: '18px',
    },
    '& svg': {
      position: 'relative',
      top: '3px',
      marginRight: '3px',
      marginLeft: '3px',
      width: '18px',
      height: '18px',
    },
  },
  card4: {
    padding: '20px 20px 0 20px',
    marginBottom: '60px',
    textAlign: 'center',
  },
  cardCategory: {
    marginBottom: '0',
    marginTop: '10px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      top: '8px',
      lineHeight: '0',
    },
    textTransform: 'uppercase',
  },
  cardCategory0: {
    color: infoColor[2],
  },
  cardCategory1: {
    color: warningColor[2],
  },
  cardCategory2: {
    color: dangerColor[0],
  },
  cardCategory3: {
    color: successColor[0],
  },
  cardCategoryPost: {
    color: '#ccc',
  },
  avatarSmall: {
    width: '25px',
    height: '25px',
    marginRight: '5px',
  },
});
