/* eslint-disable @typescript-eslint/ban-ts-ignore */

import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { parallaxStyle } from './ParallaxStyle';

const useStyles = makeStyles(parallaxStyle);

interface ParallaxProps {
  className?: string;
  filter?: 'dark';
  image: string;
  style?: object;
  small?: boolean;
}

export const Parallax: React.FC<ParallaxProps> = ({ filter, className, children, style, image, small }) => {
  const classes = useStyles();

  let windowScrollTop;
  if (typeof window !== 'undefined' && window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState('translate3d(0,' + windowScrollTop + 'px,0)');

  const resetTransform = () => {
    const windowScrollTop = window.pageYOffset / 3;
    setTransform('translate3d(0,' + windowScrollTop + 'px,0)');
  };

  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener('scroll', resetTransform);
      }
    };
  });

  const parallaxClasses = classNames({
    [classes.parallax]: true,
    // @ts-ignore not possible to augment key yet in TS https://github.com/microsoft/TypeScript/issues/12754
    [classes[filter + 'Color']]: filter !== undefined,
    [classes.small]: small,
    [className]: className !== undefined,
  });

  return (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        backgroundImage: 'url(' + image + '), linear-gradient(#212121, #414345)',
        transform: transform,
      }}
    >
      {children}
    </div>
  );
};
