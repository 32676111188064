import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { footerStyle } from './FooterStyle';
import { List, ListItem } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import { Button } from '../Button/Button';
import { GitHub, LinkedIn, Twitter } from '@material-ui/icons';

const useStyles = makeStyles(footerStyle);

interface FooterProps {
  theme: 'dark' | 'white' | 'transparent';
  big?: boolean;
  content: object;
  className?: string;
}

export const Footer: React.FC<FooterProps> = ({ big, children, content, theme, className }) => {
  const classes = useStyles();
  const themeType = theme === 'transparent' || theme === undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    // @ts-expect-error
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    // @ts-expect-error
    [className]: className !== undefined,
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          ' '
        )}
        {content}
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
};

export const DefaultFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <Footer
      theme="white"
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <GatsbyLink to={`/`} className={classes.block}>
                  Teklog
                </GatsbyLink>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                {/*Hard link, bc the Cookiebot Declaration script does not refresh properly...what a sadness */}
                <a href="/cookies" className={classes.block}>
                  Cookies
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.rightLinks}>
            <ul>
              <li>
                <Button
                  href="https://twitter.com/adamgegotek"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="transparent"
                  justIcon
                  simple
                >
                  <Twitter />
                </Button>
              </li>
              <li>
                <Button
                  href="https://github.com/tekmi"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="transparent"
                  justIcon
                  simple
                >
                  <GitHub />
                </Button>
              </li>
              <li>
                <Button
                  href="https://www.linkedin.com/in/adamgegotek/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="transparent"
                  justIcon
                  simple
                >
                  <LinkedIn />
                </Button>
              </li>
            </ul>
          </div>
          <div className={classes.pullCenter}>
            &copy; {new Date().getFullYear()}, made by{' '}
            <a href="https://tekmi.nl" target="_blank" rel="noopener noreferrer">
              TekMi
            </a>
          </div>
        </div>
      }
    />
  );
};
