import React from 'react';
import { AppBar, Toolbar, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { Button } from '../Button/Button';
import { Link as GatsbyLink } from 'gatsby';
import classNames from 'classnames';
import { headerStyle } from './HeaderStyle';

const useStyles = makeStyles(headerStyle);

interface HeaderProps {
  changeColorOnScroll?: {
    height: number;
    color: 'primary';
  };
  color: 'transparent' | 'primary';
  absolute?: boolean;
  fixed?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ changeColorOnScroll, color, fixed, absolute }) => {
  const classes = useStyles();
  // const theme = useTheme();

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (changeColorOnScroll) {
      if (windowsScrollTop > changeColorOnScroll.height) {
        document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
      } else {
        document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
      }
    }
  };

  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return (): void => {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Link className={classes.title} component={GatsbyLink} to="/">
          <Typography variant="h6">Teklog</Typography>
        </Link>
        {/*<List className={classes.list + ' ' + classes.mlAuto}>*/}
        {/*  <ListItem className={classes.listItem}>*/}
        {/*    <Button color="info" component={GatsbyLink} to="/posts">*/}
        {/*      Blog posts*/}
        {/*    </Button>*/}
        {/*  </ListItem>*/}
        {/*</List>*/}
      </Toolbar>
    </AppBar>
  );
};

export const DefaultHeader: React.FC = () => {
  return (
    <Header
      absolute
      // fixed
      color="transparent"
      changeColorOnScroll={{
        height: 200,
        color: 'primary',
      }}
    />
  );
};
